<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("late.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('late.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn v-if="listLate.length <= 0" class="btn-create" @click="onCreate()">
          {{ $t("late.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listLate.length > 0">
          <thead>
          <tr>
            <th class="text-start">
              {{ $t("late.no") }}
            </th>
            <th class="text-left">
              {{ $t("late.name") }}
            </th>
            <th class="text-left">
              {{ $t("late.value") }}
            </th>

            <th class="text-left">
              {{ $t("late.created") }}
            </th>
            <th class="text-left">
              {{ $t("late.updated") }}
            </th>
            <th class="text-left">
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listLate" :key="idx">
            <td>
              {{ (pagination.current_page * 10) - 10 + idx + 1 }}
            </td>
            <td>
              {{ item.per_minutes }}
            </td>
            <td>
              {{ $helpers.numberFormatter(item.value) }}
            </td>

            <td>
              {{ item.created_at }}
            </td>
            <td>
              {{ item.updated_at }}
            </td>

            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">{{$t("education.edit")}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">{{$t("education.delete")}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchLate"
        >
        </Pagination>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
          <template v-slot="{close}">
            <EditLate :late="late" @close="close" @success="fetchLate"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <DeleteLate :lateId="lateId" @close="close" @success="fetchLate"/>
          </template>
        </ModalDelete>


        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import Loading from "../../../components/Loading";
import EditLate from "@/components/Late/EditLate";
import DeleteLate from "@/components/Late/DeleteLate";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    EditLate,
    DeleteLate
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      searchItem: "",
      listLate: [],
      late: {},
      lateId: "",
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchLate();
    },
    onCreate() {
      this.$router.push({name: 'late.create'}).catch(() => {
      });
    },

    filterLate(lateId) {
      return (
          this.listLate.filter((item) => {
            return item.id == lateId;
          })[0] || {}
      );
    },

    onEdit(lateId) {
      this.late = {
        ...this.filterLate(lateId)
      };
      this.$store.commit("modalEdit_State", true);
    },


    onDeleted(lateId) {
      this.lateId = lateId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchLate() {
      this.isLoading = true;
      this.$axios.get(`company/list/payroll/lates`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listLate = res.data.data.data;
          console.log(this.listLate)
          this.pagination = res.data.data.pagination;
          if (!this.listLate.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
      }).catch(() => {
      })
    }
  },
  created() {
    this.fetchLate();
  }
}
</script>

<style scoped lang="scss">

</style>
